<template>
    <section class="w-100">
        <section class="notice">
            <!-- <vertical-notices v-if="vscroll" :promptedNotices="promptedNotices" /> -->
            <horizontal-notices :promptedNotices="promptedNotices" />
        </section>
        <popup-notice-component v-if="popupNotice" :notice="popupNotice" />
    </section>
</template>


<script>
import { getAppContext, scanPopupNotices } from '@/assets/js/helper';
import PopupNoticeComponent from './_PopupNotice.vue';
// import VerticalNotices from './_ScrollNotices_Vertical.vue';
import HorizontalNotices from './_ScrollNotices_Horizontal.vue';

export default {
    components: { PopupNoticeComponent, HorizontalNotices },
    props: ['promptedNotices'],

    data() {
        return {
            // The notice to be poped up.
            popupNotice: null,

            // The app context
            context: null
        };
    },

    watch: {
        'context.profile': function (to, from) {
            // only display popup notice after the user logged in.
            if (to) {
                this.showPopup();
            }
        }
    },

    created() {
        // Is the in the log-in state?
        const context = getAppContext();
        this.context = context;
        this.showPopup();
    },

    methods: {
        showPopup: function () {
            if (!this.popupNotice && this.context.profile) {
                this.popupNotice = scanPopupNotices(this.promptedNotices);
            }
        }
    }
};
</script>
<style scoped>
.page-part .notice {
    background: #C7F646FF;
}
</style>