<template>
    <section class="page login-page layout-login d-flex">
        <div class="d-flex flex-row login-left-right flex-fill">
            <div class="login-page-right login-container">
                <div class="login-box">
                    <div class="login container ">
                        <div class="login-inner" id="login-page">
                            <loading-indicator v-if="!regions" />
                            <template v-else>
                                <reset-password-component v-if="!reset_completed" :regions="regions" v-on:reset-completed="reset_completed = true" />
                                <div v-else class="container mt-5">
                                    <div class="row">
                                        <div class="col">
                                            <div class="alert alert-info">
                                                <div class="p form-group">
                                                    <div class="col">{{ $t('reset_password.succeeded') }}</div>
                                                </div>
                                                <div class="p form-group">
                                                    <div class="col">
                                                        <router-link to="/user/login">{{ $t('reset_password.label_login') }}</router-link>
                                                        |
                                                        <router-link to="/">{{ $t('reset_password.label_go_home') }}</router-link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
                <img class="loginBc1" src="@/assets/images/id-syip/loginBc1.png" alt="">
                <img class="loginBc2" src="@/assets/images/id-syip/loginBc2.png" alt="">
                <img class="loginBc3" src="@/assets/images/id-syip/loginBc3.png" alt="">
                <img class="loginBc4" src="@/assets/images/id-syip/loginBc4.png" alt="">
            </div>
        </div>
    </section>
</template>
<style scoped lang="less">
.login-left-right {
  margin: auto;
  width: 100%;
}
.login-container {
  background: linear-gradient( 297deg, #002C15 0%, #184627 92%);
  padding: 0 70px;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 0;
  overflow: hidden;
}
.loginBc1 {
  width: 370px;
  height: auto;
  position: absolute;
  left: -50px;
  top: 100px;
  z-index: -1;
}
.loginBc2 {
  width: 400px;
  height: auto;
  position: absolute;
  right: -50px;
  top: 50px;
  z-index: -1;
}
.loginBc3 {
  width: 470px;
  height: auto;
  position: absolute;
  right: -30px;
  top: 400px;
  z-index: -1;
}
.loginBc4 {
  width: 490px;
  height: auto;
  position: absolute;
  left: -150px;
  top: 300px;
  z-index: -1;
}
.login-container .login-box {
  background: none;
  width: auto;
  min-width: 45%;
  padding-left: 0;
  position: relative;
}
.login-container .img .tips {
  width: 100%;
  height: 20px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 10px;
  color: #FEFFFF;
  line-height: 10px;
  text-align: center;
  font-style: normal;
  background-color:initial;
  margin: 0;
  padding: 0 10px;
  line-height: 16px;
}
.login-container .img .title{
  font-size: 20px;
  font-weight: 600;
  color: #ffffff;
  line-height: 28px;
  text-align: center;
  padding: 0 15px;
  margin: 15px;
}
.login-box .login {
  background-color: #fff;
  border-radius: 38px 38px 38px 38px;
  border: 2px solid rgb(196, 246, 62);
}
.img {
  width: 50%;
  margin: 0;
  flex: 1;
  flex-shrink: 0;
  overflow: hidden;
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.login-container > .img img {
  max-width: 540px;
  min-width: 450px;
  height: auto;
  flex: 1;
}
#login-page {
  padding-top: 30px;
}
@media (max-width: 1024px) {
  .login-box .container {
      margin: 0 auto;
  }
  .login-container .img {
      display: none !important;
  }

  .login-container .login-box {
      width: 100%;
  }
  .login-page .login h1 {
      color: #000;
  }
  .img{
      display: none;
  }
}
@media (max-width: 768px) {
  .loginBc1 {
      width: 200px;
      top: -20px;
  }
  .loginBc2 {
      width: 150px;
      top: -50px;
  }
  .loginBc3 {
      width: 200px;
      right: -50px;
      top: 100px;
  }
  .loginBc4 {
      width: 300px;
      top: 70px;
      left: -80px;
  }
  .login-container {
      padding: 0;
      display: block;
  }
  .login-container .login-box {
      padding: 0;
      height: auto;
      border-radius: 30px;
  }
  .login-box .container {
      min-width: auto;
      margin: 0;
      width: 100%;
      padding: 0;
  }
  .login {
      position: static;
      transform: none;
      background: none;
  }
  .login-box .login {
      background: none;
      border-radius: 0;
      border: none;
      padding-top: 300px;
  }
  #login-page {
      margin: 0;
      border-radius: 0;
      padding: 30px 40px;
      max-width: none;
  }
}
</style>

<script type="text/javascript">
import ResetPasswordComponent from './Components/ResetPassword.vue';

export default {
    components: { ResetPasswordComponent },

    data() {
        return { regions: null, reset_completed: false };
    },

    created() {
        const self = this;
        $.callGetApi(this, '/api/v1/config/regions').then((resp) => {
            if (resp) {
                self.regions = resp.data;
            }
        });
    },

    watch: {
        '$i18n.locale': function () {
            this.reloadRegionsAsync();
        }
    },

    methods: {
        reloadRegionsAsync: async function () {
            // Read a list of supported regions.
            const resp = await $.callGetApi(self, '/api/v1/config/regions');
            if (resp && resp.data) {
                this.regions = resp.data;
            }
        }
    }
};
</script>