<template>
    <div v-if="filteredSymbols.length > 0">
        <section class="homepage-market d-none d-md-block">
            <div class="market-wrap">
                <dl>
                    <dt>
                        <div>{{ $t('orders.label_symbol') }}</div>
                        <div class="text-center">{{ $t('home.price') }}</div>
                        <div class="text-center">{{ $t('home.24h_change') }}</div>
                        <div class="text-center">{{ $t('home.24h_low') }}</div>
                        <div class="text-center">{{ $t('home.24h_high') }}</div>
                        <div class="text-center">{{ $t('home.symbol_action') }}</div>
                    </dt>
                    <dd v-for="(symbol, index) in filteredSymbols" :key="index" @click="gotoTrade(symbol)">
                        <router-link :to="symbol.getRoutePath()">
                            <div class="text-uppercase d-flex align-items-center text-center">
                                <div class="pe-2"><token-icon :symbol="symbol" /></div>
                                <div>{{ symbol.metadata.name }}</div>
                            </div>
                            <div growing-ignore="true" class="d-flex justify-content-center">${{ symbol.price }}</div>
                            <div growing-ignore="true" class="d-flex justify-content-center">
                                <span :class="symbol.up ? 'rate up' : 'rate down'">
                                    {{ symbol.price_change_pct }}
                                </span>
                            </div>
                            <div growing-ignore="true" class="d-flex justify-content-center">
                                <span :class="symbol.up ? 'rate up' : 'rate down'">
                                    {{ symbol.day_low }}
                                </span>
                            </div>

                            <div growing-ignore="true" class="d-flex justify-content-center">
                                <span :class="symbol.up ? 'rate up' : 'rate down'">
                                    {{ symbol.day_high }}
                                </span>
                            </div>
                            <div class="buy d-flex justify-content-center"><span>{{$t('general.trade')}}</span></div>
                        </router-link>
                    </dd>
                </dl>
            </div>
        </section>
        <section class="homepage-market-sm d-lg-none d-md-none">
            <dl>
                <dd class="title">
                    <dl>{{ $t('orders.label_symbol') }}</dl>
                    <dl>{{ $t('home.price') }}</dl>
                    <em>{{ $t('home.24h_change') }}</em>
                </dd>
                <dd v-for="(symbol, index) in filteredSymbols" :key="index" @click="gotoTrade(symbol)">
                    <dl>
                        <dt class="text-uppercase d-flex align-items-center token-name">
                            <div class="pe-2"><token-icon :symbol="symbol" /></div>
                            <div>{{ symbol.metadata.name }}</div>
                        </dt>
                        <!-- <dd growing-ignore="true">24H Vol {{ symbol.day_vol }}</dd> -->
                    </dl>
                    <dl>
                        <dt growing-ignore="true">${{ symbol.price }}</dt>
                        <dd growing-ignore="true">{{ symbol.price_change }}</dd>
                    </dl>
                    <em :class="symbol.up ? 'bg-up' : 'bg-down'" growing-ignore="true">{{ symbol.price_change_pct }}</em>
                </dd>
            </dl>
        </section>
    </div>
</template>

<script>
import { getAppContext } from 'utilities/helper';
import TokenIcon from '../../Components/TokenIcon.vue';

export default {
    components: { TokenIcon },
    props: ['title', 'symbols', 'typeFilter', 'searchTerm'],
    computed: {
        filteredSymbols: function () {
            if (!this.symbols) {
                return [];
            }

            // Determines if test mode is enabled or not for the current user.
            const context = getAppContext();
            let testMode = false;
            if (context && context.profile) {
                testMode = context.profile.testEnabled === true;
            }

            const self = this;
            const term =
                this.searchTerm && typeof this.searchTerm === 'string'
                    ? this.searchTerm.trim().toUpperCase() // Always use upper case
                    : '';
            if (term && term.length > 0) {
                let data = this.symbols.filter((s) => {
                    return s.metadata.type === self.typeFilter && (testMode || !s.metadata.testOnly) && s.metadata.name.indexOf(term) >= 0;
                });
                if(this.$route.path==='/'){
                    data= data.slice(0,5);
                }
                return data
            } else {
                // Return symbols for the specified type.
                let data = this.symbols.filter((s) => {
                    return s.metadata.type === self.typeFilter && (testMode || !s.metadata.testOnly);
                });
                if(this.$route.path==='/'){
                    data= data.slice(0,5);
                }
                return data
            }
        }
    },
    methods: {
        gotoTrade: function (sym) {
            this.$emit('symbol-selected', sym);
        },

        getVisibleSids: function () {
            const output = [];
            $(this.filteredSymbols).each((index, sym) => {
                output.push(sym.metadata.id);
            });

            return output;
        }
    }
};
</script>
<style scoped>
.homepage-market .market-wrap dl dt > div,.homepage-market .market-wrap dl dt > div, .homepage-market .market-wrap dl dd > a > div {
    color: #9194a4;
}
.homepage-market-sm > dl > dd > dl,.homepage-market-sm em  {
   color: #9194a4; 
}
.homepage-market .market-wrap dl dd a .buy span {
    color: #3A9A5D;
    display: inline-block;
}
.market-wrap dl dt>div:first-child{
    text-indent: 4em;
}
@media screen and ( min-width :768px) and ( max-width :1024px) {
    .homepage-market-sm{
        display:  block !important;
    }
    .homepage-market{
        display:  none !important;
    }
}
</style>