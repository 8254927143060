var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"page home-page"},[_c('section',{staticClass:"pro_layout"},[(!_vm.symbols)?_c('loading-indicator'):_c('div',[_c('div',{staticClass:"home-top"},[(_vm.context.homeConfig)?_c('top-banners',{attrs:{"home_config":_vm.context.homeConfig}}):_vm._e()],1),_vm._v(" "),(
          _vm.context.homeConfig &&
          _vm.context.homeConfig.promptedNotices &&
          _vm.context.homeConfig.promptedNotices.length
        )?_c('div',{staticClass:"page-part"},[_c('home-notices',{attrs:{"promptedNotices":_vm.context.homeConfig.promptedNotices}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"d-block d-md-none sm-navs"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('router-link',{attrs:{"to":"/user/login"}},[_c('svg',{staticClass:"svg-icon",attrs:{"viewBox":"0 0 256 256"}},[_c('use',{attrs:{"xlink:href":"/dist/svg/icons.svg#v4.2-login"}})]),_vm._v("\n                "+_vm._s(_vm.$t("general.login"))+"/"+_vm._s(_vm.$t("general.register"))+"\n              ")])],1),_vm._v(" "),_c('div',{staticClass:"col"},[(_vm.sysconfig.savingsEnabled)?_c('router-link',{attrs:{"to":"/saving"}},[_c('svg',{staticClass:"svg-icon",attrs:{"viewBox":"0 0 256 256"}},[_c('use',{attrs:{"xlink:href":"/dist/svg/icons.svg#v4.2-wallet"}})]),_vm._v("\n                "+_vm._s(_vm.$t("home.header_saving", { platform: _vm.sysconfig.name }))+"\n              ")]):_c('router-link',{attrs:{"to":"/user/balance"}},[_c('svg',{staticClass:"svg-icon",attrs:{"viewBox":"0 0 256 256"}},[_c('use',{attrs:{"xlink:href":"/dist/svg/icons.svg#v4.2-wallet"}})]),_vm._v("\n                "+_vm._s(_vm.$t(
                    _vm.sysconfig.is_hkmy
                      ? "home.header_buy_crypto_my"
                      : "home.header_buy_crypto"
                  ))+"\n              ")])],1),_vm._v(" "),_c('div',{staticClass:"col"},[_c('router-link',{attrs:{"to":"/startup"}},[_c('svg',{staticClass:"svg-icon",attrs:{"viewBox":"0 0 256 256"}},[_c('use',{attrs:{"xlink:href":"/dist/svg/icons.svg#v4.2-assets"}})]),_vm._v("\n                "+_vm._s(_vm.$t(
                    _vm.sysconfig.is_hk ? "general.startup_hk" : "general.startup"
                  ))+"\n              ")])],1),_vm._v(" "),_c('div',{staticClass:"col"},[_c('router-link',{attrs:{"to":"/notices"}},[_c('div',{staticClass:"svg-box"},[_c('svg',{staticClass:"svg-icon notice-svg",attrs:{"viewBox":"0 0 256 256"}},[_c('use',{staticClass:"use-svg",attrs:{"xlink:href":"/dist/svg/icons.svg#v4.2-notice"}})])]),_vm._v("\n                "+_vm._s(_vm.$t("notices.notice_title"))+"\n              ")])],1)])])]),_vm._v(" "),_c('inline-svg-icons',{attrs:{"symbols":_vm.symbols}}),_vm._v(" "),_c('section',{staticClass:"home-heade"},[_c('div',{staticClass:"top container"},[_c('img',{staticClass:"img1",attrs:{"src":require("@/assets/images/id-syip/okx.png")}}),_vm._v(" "),_c('img',{staticClass:"img2",attrs:{"src":require("@/assets/images/id-syip/coinbace.png")}}),_vm._v(" "),_c('img',{staticClass:"img3",attrs:{"src":require("@/assets/images/id-syip/binance.png")}})]),_vm._v(" "),_c('div',{staticClass:"down container"},[_c('div',{staticClass:"title1"},[_vm._v(" "+_vm._s(_vm.$t("home.home_need")))]),_vm._v(" "),_c('div',{staticClass:"title2"},[_vm._v(" "+_vm._s(_vm.$t("home.home_fluidity")))]),_vm._v(" "),_c('div',{staticClass:"content"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("home.home_fluidity")))]),_vm._v(" "),_c('div',{staticClass:"content1"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("@/assets/images/id-syip/gold.png")}})]),_vm._v(" "),_c('div',{staticClass:"text"},[_vm._v("\n                    "+_vm._s(_vm.$t("home.features_safety_desp"))+"\n                  ")])])]),_vm._v(" "),_c('div',{staticClass:"right"})])])]),_vm._v(" "),_c('section',{staticClass:"home-section"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"main-block-wrapper"},[_c('home-quotes',{ref:"quotes",attrs:{"symbols":_vm.symbols}})],1)])])])]),_vm._v(" "),_c('introduction'),_vm._v(" "),(
          _vm.context && _vm.context.homeConfig && _vm.context.homeConfig.spl === true
        )?_c('partner-links'):_vm._e()],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }