<template>
    <div class="container">
        <div class="user-link-box">
            <div class="user-link-item">
                <div class="link-item-left">
                    <h4>{{ $t('user.label_security') }}</h4>
                    <div class="item-link-box">
                        <router-link to="/user/ga" class="item-link">
                            <div class="link-image">
                                <img src="@/assets/images/id-syip/userGa.png" alt="">
                                <span class="link-des">{{ $t('ga.title') }}</span>
                            </div>
                            <img src="@/assets/images/id-syip/userGo.png" alt="" class="link-btn">
                        </router-link>
                        <router-link to="/user/email" class="item-link">
                            <div class="link-image">
                                <img src="@/assets/images/id-syip/userEmail.png" alt="">
                                <span class="link-des">{{ $t('register.label_email') }}</span>
                            </div>
                            <img src="@/assets/images/id-syip/userGo.png" alt="" class="link-btn">
                        </router-link>
                    </div>
                </div>
                <div class="link-item-right">
                    <h4>{{ $t('user.label_id_verification') }}</h4>
                    <router-link to="/user/verifyid" class="id-image-box">
                        <img src="@/assets/images/id-syip/userIdentity.png" alt="" class="id-image">
                        <div class="id-info">
                            <div class="id-title">{{ $t('user.label_id_verified') }}</div>
                            <div class="id-des">{{ $t('user.label_id_verification_desp') }}</div>
                        </div>
                    </router-link>
                </div>
            </div>
            <div class="user-link-item">
                <div class="link-title-box">
                    <h5 class="link-title">{{ $t('assets.total_assets') }}</h5>
                    <router-link to="/user/balance" class="to-assets">
                        <img src="@/assets/images/id-syip/userAsset.png" alt="">
                        {{ $t('assets.title') }}
                    </router-link>
                </div>
                <div class="assets-num">{{parseFloat(totalMoney).toFixed(4)}}</div>
                <div class="assets-unit">USDT</div>
            </div>
            <div class="container image-link-box">
                <router-link to="/user/ga" class="image-link">
                    <img src="@/assets/images/id-syip/userGa.png" alt="">
                    <div class="link-name">{{ $t('ga.title') }}</div>
                </router-link>
                <router-link to="/user/email" class="image-link">
                    <img src="@/assets/images/id-syip/userEmail.png" alt="">
                    <div class="link-name">{{ $t('register.label_email') }}</div>
                </router-link>
                <router-link to="/user/verifyid" class="image-link">
                    <img src="@/assets/images/id-syip/userIdentity.png" alt="">
                    <div class="link-name">{{ $t('user.label_id_verification') }}</div>
                </router-link>
            </div>
            <div class="user-link-item">
                <router-link to="/user/balance" class="go-withdraw">
                    <img src="@/assets/images/id-syip/userRecharge.png" alt="">
                    <div class="link-item-info">
                        <div class="link-item-title">{{ $t('general.deposit') }}</div>
                        <div class="link-item-des">{{ $t('intro_43.icon21') }}</div>
                    </div>
                </router-link>
                <router-link to="/user/balance" class="go-withdraw">
                    <img src="../../../assets/images/id-syip/userWithdraw.png" alt="">
                    <div class="link-item-info">
                        <div class="link-item-title">{{ $t('general.withdraw') }}</div>
                        <div class="link-item-des">{{ $t('intro.md_3') }}</div>
                    </div>
                </router-link>
            </div>
        </div>

        <div class="card-list">
            <router-link to="/user/phone" class="card-item">
                <div class="card-left">
                    <img src="@/assets/images/id-syip/userPhone2x.png" alt="" class="card-item-icon">
                    <div class="card-item-box">
                        <div class="card-item-title">{{ $t('user.label_account') }}</div>
                        <div class="card-item-des">{{ $t('user.label_account') }}</div>
                    </div>
                </div>
                <svg viewBox="0 0 256 256">
                    <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                </svg>
            </router-link>
            <router-link to="/user/changewithdrawpwd" class="card-item" v-if="sysconfig.requiresWithdrawPassword === true">
                <div class="card-left">
                    <img src="@/assets/images/id-syip/payPassword2x.png" alt="" class="card-item-icon">
                    <div class="card-item-box">
                        <div class="card-item-title">{{ $t('user.label_financial_password') }}</div>
                        <div class="card-item-des">{{ $t('withdraw_password.title') }}</div>
                    </div>
                </div>
                <svg viewBox="0 0 256 256">
                    <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                </svg>
            </router-link>
            <router-link to="/user/balance" class="card-item">
                <div class="card-left">
                    <img src="@/assets/images/id-syip/userBanlance2x.png" alt="" class="card-item-icon">
                    <div class="card-item-box">
                        <div class="card-item-title">{{ $t('home.header_buy_crypto') }}</div>
                        <div class="card-item-des">{{ $t('user.label_id_verification_desp') }}</div>
                    </div>
                </div>
                <svg viewBox="0 0 256 256">
                    <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                </svg>
            </router-link>
            <router-link to="/user/bankaccounts" class="card-item">
                <div class="card-left">
                    <img src="@/assets/images/id-syip/payBankcard2x.png" alt="" class="card-item-icon">
                    <div class="card-item-box">
                        <div class="card-item-title">{{$t('user.label_bankaccount')}}</div>
                        <div class="card-item-des">{{ $t('bank_account.label_confirm') }}</div>
                    </div>
                </div>
                <svg viewBox="0 0 256 256">
                    <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                </svg>
            </router-link>
            <router-link to="/notices" class="card-item">
                <div class="card-left">
                    <img src="@/assets/images/id-syip/userNotices2x.png" alt="" class="card-item-icon">
                    <div class="card-item-box">
                        <div class="card-item-title">{{ $t('notices.notice_title')}}</div>
                        <div class="card-item-des">{{ $t('notices.notice_title')}}</div>
                    </div>
                </div>
                <svg viewBox="0 0 256 256">
                    <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                </svg>
            </router-link>
            <router-link to="/user/reset" class="card-item">
                <div class="card-left">
                    <img src="@/assets/images/id-syip/userReset2x.png" alt="" class="card-item-icon">
                    <div class="card-item-box">
                        <div class="card-item-title">{{ $t('user.label_password')}}</div>
                        <div class="card-item-des">{{ $t('user.label_password')}}</div>
                    </div>
                </div>
                <svg viewBox="0 0 256 256">
                    <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                </svg>
            </router-link>
            <router-link to="/futures/openorders" class="card-item">
                <div class="card-left">
                    <img src="@/assets/images/id-syip/futures2x.png" alt="" class="card-item-icon">
                    <div class="card-item-box">
                        <div class="card-item-title">{{ $t('home.header_futures_orders') }}</div>
                        <div class="card-item-des">{{ $t('home.header_futures_orders') }}</div>
                    </div>
                </div>
                <svg viewBox="0 0 256 256">
                    <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                </svg>
            </router-link>
            <router-link to="/exchange/orders" class="card-item">
                <div class="card-left">
                    <img src="@/assets/images/id-syip/exchange2x.png" alt="" class="card-item-icon">
                    <div class="card-item-box">
                        <div class="card-item-title">{{ $t('home.header_exchange_orders') }}</div>
                        <div class="card-item-des">{{ $t('home.header_exchange_orders') }}</div>
                    </div>
                </div>
                <svg viewBox="0 0 256 256">
                    <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                </svg>
            </router-link>
            <router-link to="/boption/orders" class="card-item">
                <div class="card-left">
                    <img src="@/assets/images/id-syip/options2x.png" alt="" class="card-item-icon">
                    <div class="card-item-box">
                        <div class="card-item-title">{{ $t('home.header_boption_orders') }}</div>
                        <div class="card-item-des">{{ $t('home.header_boption_orders') }}</div>
                    </div>
                </div>
                <svg viewBox="0 0 256 256">
                    <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                </svg>
            </router-link>
            <router-link to="/user/signout" class="card-item">
                <div class="card-left">
                    <img src="@/assets/images/id-syip/userLoginout2x.png" alt="" class="card-item-icon">
                    <div class="card-item-box">
                        <div class="card-item-title">{{ $t('user.label_signout') }}</div>
                        <div class="card-item-des">{{ $t('user.label_signout') }}</div>
                    </div>
                </div>
                <svg viewBox="0 0 256 256" >
                    <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                </svg>
            </router-link>
        </div>
    </div>
    
</template>

<script type="text/javascript">
export default { 
    props: ['profile'],
    data(){
        return {
            bankaccount:[],
            totalMoney:0
        }
    },
    created(){
        this.initAsync();
        this.getTotalMoney();
    },
    methods:{
        initAsync: async function () {
            // List all accounts with payment source as bank.
            const json = await $.callGetApi(this, '/api/v1/bankaccount/list?id=bank');
            if (json.errcode === 0) {
                this.bankaccount = json.data;
            }
        },
        getTotalMoney:async function(){
            const self = this;
            const balances = await $.callPostApi(this, '/api/v1/balance/list');

            for (let balance in balances) {
                if (balances[balance].displayName == 'USDT') {
                    self.totalMoney = balances[balance].balance;
                }

            }
        }
    }
};
  
</script>
<style scoped>
.container {
    box-sizing: border-box;
    padding: 0;
}
.image-link-box {
    display: none;
    justify-content: space-evenly;
}
.image-link {
    text-align: center;
}
.image-link img {
    width: 45px;
    height: 45px;
    margin-bottom: 20px;
}
.user-link-item {
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #E9E9E9;
    margin-bottom: 20px;
    padding: 12px;
}
.link-item-left h4 {
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 18px;
    color: #000000;
    margin-bottom: 14px;
}
.link-item-right h4 {
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 18px;
    color: #000000;
    margin-bottom: 14px;
    margin-bottom: 30px;
}
.user-link-item:first-child {
    border: none;
    display: flex;
    justify-content: space-between;
    padding: 0;
}
.link-item-left,.link-item-right {
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #E9E9E9;
    padding: 12px;
    width: 48%;
}
.link-item-left {
    margin-right: 20px;
}
.item-link-box {
    display: flex;
    justify-content: space-between;
}
.item-link {
    display: flex;
    width: 45%;
    justify-content: space-between;
    align-items: center;
}
.link-image img {
    width: 30px;
    height: 30px;
    display: block;
}
.link-image .link-des {
    font-size: 12px;
    color: #9B9B9B;
    margin-top: 8px;
    display: block;
}
.link-btn {
    width: 24px;
    height: 24px;
}
.id-image-box {
    display: flex;
    align-items: center;
}
.id-info .id-des {
    font-weight: 400;
    font-size: 12px;
    color: #9B9B9B;
    line-height: 17px;
    margin-top: 5px;
}
.id-image {
    width: 40px;
    height: 40px;
    margin-right: 16px;
}
.link-title-box {
    display: flex;
    justify-content: space-between;
}
.link-title-box .link-title {
    font-size: 18px;
    color: #000000;
    line-height: 25px;
    font-weight: 600;
}
.to-assets {
    display: flex;
    align-items: center;
    border-radius: 13px 13px 13px 13px;
    border: 1px solid #E9E9E9;
    padding: 5px 10px;
}
.to-assets img {
    width: 14px;
    height: 14px;
    margin-right: 5px;
}
.assets-num {
    margin-top: 18px;
    font-weight: 500;
    font-size: 28px;
    color: #000000;
    line-height: 29px;
    margin-bottom: 12px;
}
.assets-unit {
    font-weight: 400;
    font-size: 12px;
    color: #9B9B9B;
    line-height: 17px;
}
.go-withdraw {
    display: flex;
    align-items: center;
}
.go-withdraw:first-child {
    margin-bottom: 20px;
}
.go-withdraw img {
    width: 34px;
    height: 34px;
    margin-right: 12px;
}
.link-item-title {
    font-weight: 600;
    font-size: 14px;
    color: #000000;
    line-height: 16px;
}
.link-item-des {
    font-weight: 400;
    font-size: 12px;
    color: #9B9B9B;
    line-height: 14px;
}


.card-list h4 {
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 25px;
    color: #121836;
    line-height: 45px;
    margin-bottom: 40px;
}
.card-item {
    border-radius: 6px 6px 6px 6px;
    margin-bottom: 20px;
    display: flex;
    padding: 20px 40px 20px 13px;
    justify-content: space-between;
    align-items: center;
    border: 1px solid rgba(197,197,197,0.48);
}
.card-item:last-child {
    margin-bottom: 0;
}
.card-item svg {
    height: 20px;
    width: 20px;
}
.card-left {
    display: flex;
    align-items: center;
}
.card-item-icon {
    height: 35px;
    width: auto;
    margin-right: 20px;
}
.card-item-title {
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 18px;
    color: #000000;
    line-height: 25px;
    margin-bottom: 5px;
}
.card-item-des {
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #999999;
    line-height: 20px;
}
.card-item-button {
    background: rgba(0,0,0,0.1);
    border-radius: 387px 387px 387px 387px;
    padding: 6px 27px;
    color: #333333;
    font-size: 14px;
    white-space: nowrap;
}

.user-info{
    background: #fff;
    color: var(--primary-base);
    padding: 0px;
    height: initial;
    margin-bottom: 0px;
}
.settings-page h1 {
    margin-top: 0px;
}
.real-name svg{
    margin-top: 0px;
    position: relative;
}
.real-name{
    margin-left: 0px;
    padding-left: 1rem;
}
.google .flex-fill{
    margin-left: 10px;
}
.google svg{
    margin-top: 0px;
}
.container {
    margin-bottom: 20px;
}
@media (max-width: 1024px) {
    .d-none {
        display: none !important;
    }

    .d-block {
        display: block !important;
    }
    .container {
        border-radius: 21px;
        padding: 28px;
    }
    .go-withdraw img {
        width: 20px;
        height: 20px;
    }
    .link-item-info {
        display: flex;
    }
    .link-item-des {
        margin-left: 5px;
    }
    .image-link-box {
        display: flex;
    }
    .user-link-item:first-child,
    .user-link-item:nth-child(2){
        display: none;
    }
    .card-item {
        padding: 10px 0;
        border: none;
        border-bottom: 1px solid #d8d8d8;
        margin-bottom: 0;
        border-radius: 0;
    }
    .card-item:last-child {
        margin-bottom: 0;
    }
    .card-item-title {
        font-size: 16px;
    }
    .card-item-icon {
        height: 25px;
    }
}
</style>