/**
 * Add extensive functions to Date, $, etc.
 */


/*
 * ======================================================================================
 * Import third party dependencies
 */
window.jQuery = window.$ = require('jquery');

// Import jquery validation
import 'jquery-validation';
import "jquery-validation-unobtrusive"

// Import bootstrap script
// import 'popper.js';
// import 'bootstrap';
import "bootstrap/dist/js/bootstrap.bundle.min.js"

// Import socket.io client
import { io } from "socket.io-client";

import Vue from 'vue';
window.Vue = Vue;


/*
 * ======================================================================================
 * Extend Date functions
 */
const TimeString = "00 01 02 03 04 05 06 07 08 09 10 11 12 13 14 15 16 17 18 19 20 21 22 23 24 25 26 27 28 29 30 31 32 33 34 35 36 37 38 39 40 41 42 43 44 45 46 47 48 49 50 51 52 53 54 55 56 57 58 59".split(" ");
Date.prototype.formatDate = function () {
    const arr = [];
    arr.push(this.getFullYear());

    // The getMonth() method returns the month in the specified this according to local time,
    // as a zero - based value(where zero indicates the first month of the year).
    const m = this.getMonth() + 1;
    arr.push(TimeString[m]);

    const date = this.getDate();
    arr.push(TimeString[date]);
    return arr.join('-');
};

Date.prototype.formatShortDate = function () {
    const arr = [];
    // The getMonth() method returns the month in the specified this according to local time,
    // as a zero - based value(where zero indicates the first month of the year).
    arr.push(TimeString[this.getMonth() + 1]);
    arr.push(TimeString[this.getDate()]);
    return arr.join('-');
};

Date.prototype.formatDateTime = function (has_seconds) {
    const arr = [];
    arr.push(this.getFullYear());

    // The getMonth() method returns the month in the specified date according to local time,
    // as a zero - based value(where zero indicates the first month of the year).
    arr.push('-' + TimeString[this.getMonth() + 1]);
    arr.push('-' + TimeString[this.getDate()]);

    // Append time part
    arr.push(' ' + TimeString[this.getHours()]);
    arr.push(':' + TimeString[this.getMinutes()]);
    if (has_seconds === true) {
        arr.push(':' + TimeString[this.getSeconds()]);
    }
    return arr.join('');
};

Date.prototype.formatTime = function (has_seconds) {
    const arr = [];

    arr.push(TimeString[this.getHours()]);
    arr.push(':' + TimeString[this.getMinutes()]);
    if (has_seconds === true) {
        arr.push(':' + TimeString[this.getSeconds()]);
    }
    return arr.join('');
};



/*
 * ======================================================================================
 * Extend number functions
 */
Number.prototype.floor = function (precision) {
    if (!precision || precision <= 0)
        return Math.floor(this);

    let s = this.toFixed(precision + 1);
    return s.substring(0, s.length - 1);
};

Number.prototype.formatBalance = function (precision) {
    if (precision <= 0)
        return Math.floor(this);

    const min = 1 / Math.pow(10, precision);
    if (Math.abs(this) >= min) {
        let s = this.toString();
        let pos = s.indexOf('.');
        if (pos > 0 && s.length - pos - 1 > precision) {
            return s.substring(0, pos + 1 + precision) * 1;
        }
        return this;
    } else {
        // Balance number will have up to 8 digits.    
        let s = this.toFixed(8);

        // Remove zero suffix;
        return s.replace(/((\.0+)|(0+))$/, '');
    }
};

Number.prototype.toFixedBalance = function (precision) {
    if (precision <= 0)
        return Math.floor(this);

    if (precision >= 8)
        return this.toFixed(8);

    let s = this.toFixed(8);
    return s.substring(0, s.length - (8 - precision));
};

Number.prototype.toBalanceString = function () {
    // Balance number will have up to 8 digits.
    let s = this.toFixed(8);

    // Remove zero suffixes.
    // Find the position of the dot (.) character.
    let dotPos = s.indexOf('.');
    if (dotPos > 0) {
        // Find the last non-zero character until the dot (.)
        let endPos = s.length - 1;
        while (endPos > dotPos) {
            if (s[endPos] !== '0') {
                break;
            }
            endPos--;
        }
        if (endPos === dotPos) {
            // Excluding the dot (.) character
            endPos--;
        } else {
            // The number could be in format like: 1.2e+30;
            // It won't be possible to use the format like 1.2e-30 because toFixed(8) will convert it to zero.
            if (s.indexOf('+') > dotPos) {
                return s;
            }
        }

        return s.substring(0, endPos + 1);
    }

    // This should not happen. But just in case...
    return s;
}


/*
 * ======================================================================================
 * Extend jQuery to add common methods
 */
$.g_quoteDataCallbacks = [];
$.g_quoteSocket = null;
$.g_quoteTimerId = 0;

$.extend({
    // The following code has been moved to Entry.vue.
    //
    // /**
    //  * display an message at page top
    //  * @param {any} msg The message to display
    //  * @param {any} is_error Indicates whether this is an error message
    //  */
    // top_alert: function (msg, is_error) {
    //     $('.toast .toast-body').text(msg);
    //     $('.toast').toggleClass('has-error', !!is_error).toast('show');
    // },


    // /**
    //  * display error message at page top
    //  * @param {any} msg The error message.
    //  */
    // top_error: function (msg) {
    //     $.top_alert(msg, true);
    // },

    resetValidators: function () {
        $("form").removeData("validator").removeData("unobtrusiveValidation");
        $.validator.unobtrusive.parse('form');
    },

    validate_phone: function (val, countryCode) {
        val = '' + $.trim(val);
        if (countryCode === '+86') {
            // Special case for Chinese phone numbers.
            // 1) The length must be 11.
            // 2) Must start with "1".
            //
            if (val.length !== 11)
                return null;

            var ch = val.charAt(0);
            if (ch !== '1') return null;

            for (let i = 1; i < 11; i++) {
                ch = val.charAt(i) * 1;
                if (ch > 9 || ch < 0)
                    return null;
            }
        } else {
            // For other contries:
            // 1) The lenght must be between 6 and 16.
            // 2) All characters must be numbers.
            //
            if (val.length < 6 || val.length > 16)
                return null;
            for (let i = 1; i < 11; i++) {
                ch = val.charAt(i) * 1;
                if (ch > 9 || ch < 0)
                    return null;
            }
        }
        return val;
    },

    validate_email: function (val) {
        val = '' + $.trim(val);

        if (true === /^[\w\-\.]+@[\w\-\.]+$/ig.test(val))
            return val;
        return null;
    },


    /*
    subscribeQuotationData: function (callback) {
        if (callback) {
            $.g_quoteDataCallbacks.push(callback);
        }
    },

    _onQuotationData: function (data) {
        for (var i = 0; i < $.g_quoteDataCallbacks.length; i++) {
            $.g_quoteDataCallbacks[i](data);
        }
    },
    */


    /**
     * start receiving real time quote data using socket.io
     * @param {any} ns The namespace of the connection.
     * @param {function} quote_callback The callback to be called upon quote data
     */
    initSocketIo: function (ns, quote_callback, depth_callback) {
        if (!ns || typeof ns !== 'string' || !ns.length)
            throw new Error('Invalid namespace: ' + ns);

        if (!quote_callback || typeof quote_callback !== 'function')
            throw new Error('A callback function is expected.');

        // Close existing connection
        $.closeSocketIo();

        // Create a new connection
        var socket = io(g_rtqs_path + ns, { transports: ['websocket'] });
        socket.on('reconnect_attempt', function () {
            socket.io.opts.transports = ['polling', 'websocket'];
        });

        // subscribe quote data
        socket.on('qs', quote_callback);

        // subscribe depth data for futures symbols.
        if (depth_callback) {
            socket.on('depth', depth_callback);
        }

        socket.on('connection', function () {
            console.log("# quote client connected");
        });
        socket.on('connect_error', function (err) {
            console.log("# quote client connect_error: ", err);
        });
        socket.on('connect_timeout', function (err) {
            console.log("# quote client connect_timeout: ", err);
        });

        // save the socket reference in case it was garbage collected.
        $.g_quoteSocket = socket;

        // Create a timer to read latest quote
        if ($.g_quoteTimerId) {
            clearTimeout($.g_quoteTimerId);
        }

        // ns: f101, etc.
        let sid = ns.substring(2) * 1;
        console.log(`## full quote client for ${sid} (ns=${ns})`);
        if (!isNaN(sid) && sid > 0) {
            const timer_func = () => {
                // Is the socket io connection active?
                if ($.g_quoteSocket) {
                    // Read latest quote
                    $.get('/api/v1/quotation/full_quote?id=' + sid).done((json) => {
                        if (json && json.quote) {
                            const q = json.quote;
                            if (q.id === sid) {
                                quote_callback({ t: q.t, id: sid, v: [q.ds, q.c, q.dl, q.dh, q.v, q.dv] });
                            }

                            if (json.depth && depth_callback) {
                                depth_callback(json.depth);

                                if (json.depth_age >= 10 * 1000) {
                                    console.error(`#### DEPTH DATA TOO OLD. Age=${json.depth_age}ms`);
                                }
                            }
                        }
                    }).always(() => {
                        $.g_quoteTimerId = setTimeout(timer_func, 1500 + Math.round(Math.random() * 1000));
                    });
                }
            };
            $.g_quoteTimerId = setTimeout(timer_func, 1500 + Math.round(Math.random() * 1000));
        }
    },


    /**
     * close the socket.io connection manually
     */
    closeSocketIo: function () {
        var socket = $.g_quoteSocket;
        $.g_quoteSocket = null;
        if (socket) {
            socket.close();
        }

        // Stop the quote timer
        clearTimeout($.g_quoteTimerId);
        $.g_quoteTimerId = 0;
    }
});


/*
 * ======================================================================================
 * jquery extension methods
 */
$.fn.extend({
    serializeAsJson: function (skipTrim) {
        const data = {};
        this.serializeArray().map(function (item) {
            data[item.name] = skipTrim ? item.value : $.trim(item.value);
        });

        return data;
    },

    // Allow use the element to copy content from the specified target
    enable_copy: function () {
        // determines if the copy command is supported or not?
        let support = !!document.queryCommandSupported;
        support = support && !!document.queryCommandSupported('copy');
        if (!support)
            return;

        const init_single_elm = function () {
            // make sure the target is specified.
            const src = $(this);
            const targetAttr = src.attr("data-target");
            if (!targetAttr)
                return; // No target was specified.

            const target = $(targetAttr);
            if (target.length !== 1)
                return;

            // add click event for the source element
            src.on('click', function (event) {
                event.preventDefault();

                const copy_to_clipboard = function () {
                    let targetElm = target[0];

                    // for Internet Explorer
                    if (document.body.createTextRange) {
                        let range = document.body.createTextRange();
                        range.moveToElementText(targetElm);
                        range.select();
                    }
                    else if (window.getSelection) {
                        // other browsers
                        let selection = window.getSelection();
                        let range = document.createRange();
                        range.selectNodeContents(targetElm);
                        selection.removeAllRanges();
                        selection.addRange(range);
                    }

                    document.execCommand('copy');
                };

                // gets the text from the target element
                // let text = target.text() || target.val();
                // copy_to_clipboard(text);
                copy_to_clipboard();

                $.top_alert($.getLocaleMessage('general.copied'), false);

                return false;
            });

            // remove 'hide' class from the source element
            src.removeClass('hide');
        };

        $(this).each(init_single_elm);
    }
});
