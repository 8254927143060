<template>
    <section>
        <!-- Bank accounts must be configured -->
        <div v-if="bankAccounts && bankAccounts.length === 0" class="alert alert-info mt-3">
            <p>{{ $t('withdraw.missing_bank_account') }}</p>
            <p>
                <router-link to="/user/bankaccount">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="1.25rem" height="1.25rem">
                        <path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 24 13 L 24 24 L 13 24 L 13 26 L 24 26 L 24 37 L 26 37 L 26 26 L 37 26 L 37 24 L 26 24 L 26 13 L 24 13 z" />
                    </svg>
                    {{ $t('bank_account.label_add') }}
                </router-link>
            </p>
        </div>

        <!-- Reading fiat config -->
        <loading-indicator v-else-if="!fiatConfig" />

        <!-- The withdraw form -->
        <form v-else class="form-horizontal" method="post" action="javascript:" @submit="doConfirm">
            <!-- Bank Account -->
            <div class="form-group row">
                <div class="col">
                    <label class="form-label">{{ $t('bank_account.label_bank_account') }} </label>
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <select v-model="bankAccountUid" name="BankAccountId" class="form-control" data-val="true" data-val-required="*">
                                <option value="">{{ $t('withdraw.label_select_bank_account') }}</option>
                                <option value="" disabled>-----------------</option>
                                <option v-for="acc in bankAccounts" :key="acc.uid" :value="acc.uid">{{ acc.bank }} {{ acc.bankCardNo }} {{ acc.bankCardHolder }}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Withdraw amount -->
            <div class="form-group row">
                <div class="col">
                    <label class="form-label">{{ $t('withdraw.label_amount') }} </label>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="input-group">
                                <input type="text" name="Amount" v-model="amount" class="form-control" data-val="true" data-val-required="*" data-val-number="*" data-val-range="*" v-bind:data-val-range-min="fiatConfig.minWithdrawAmount" v-bind:data-val-range-max="maxAmountPerOrder" />
                                <div class="input-group-text">{{ currencySymbol }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="help-block">
                        <div>
                            {{ $t('withdraw.label_available_amount') }} <span>{{ fiatConfig.maxAvailableAmount.toFixed(2) }} {{ currencySymbol }}</span>
                        </div>
                        <div>
                            <span v-if="fiatConfig.maxWithdrawAmount < 0x7fffffff">
                                {{
                                    $t('withdraw.label_amount_range', {
                                        min: fiatConfig.minWithdrawAmount,
                                        max: fiatConfig.maxWithdrawAmount,
                                        currency_symbol: currencySymbol
                                    })
                                }}
                            </span>
                            <span v-else>{{ $t('withdraw.label_min_amount', { min: fiatConfig.minWithdrawAmount, currency_symbol: currencySymbol }) }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Withdraw password -->
            <div class="form-group row" v-if="fiatConfig.requiresWithdrawPassword === true">
                <div class="col">
                    <label class="form-label">{{ $t('withdraw.label_withdraw_pwd') }} </label>
                    <div class="row">
                        <div class="col-md-4">
                            <input type="password" name="WithdrawPassword" v-model="password" class="form-control" autocomplete="off" maxlength="16" />
                        </div>
                    </div>
                </div>
            </div>

            <!-- Confirm button -->
            <div class="form-group row">
                <div class="col col-md-4">
                    <button type="submit" class="btn btn-submit">{{ $t('general.submit') }}</button>
                </div>
            </div>
        </form>

        <!-- A confirmation modal -->
        <div ref="confirmModal" class="modal fade" tabindex="-1" role="dialog">
            <div class="modal-dialog">
                <div class="modal-content">
                    <form action="javascript:void(0);" method="post" @submit="doSubmit">
                        <!-- modal header -->
                        <div class="modal-header">
                            <h4 class="modal-title">{{ $t('general.confirm') }}</h4>
                        </div>

                        <!-- modal body -->
                        <div class="modal-body">
                            <input type="hidden" name="BankAccountId" v-model="bankAccountUid" />
                            <input type="hidden" name="Amount" v-model="amount" />
                            <input type="hidden" name="WithdrawPassword" v-model="password" />
                            <div class="form-group row">
                                <div class="col">
                                    <label class="form-label"> {{ $t('withdraw.label_amount') }} </label>
                                    <div class="row">
                                        <div class="col-md-4">{{ amount }} {{ currencySymbol }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- modal footer -->
                        <div class="modal-footer">
                            <button type="button" class="btn btn-default cancel-button" data-bs-dismiss="modal">{{ $t('general.cancel') }}</button>
                            <button type="submit" class="btn btn-primary" :class="{ 'btn-loading': submitting }" :disabled="submitting">{{ $t('general.confirm') }}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { Modal } from 'bootstrap';

export default {
    data() {
        return {
            // Config for fiat withdraws: fiat withdraws are only for USDT.
            fiatConfig: null,
            bankAccounts: null,

            amount: '',
            password: '',
            bankAccountUid: '',

            submitting: false,

            // The popup confirmation modal.
            popupModal: null
        };
    },

    computed: {
        maxAmountPerOrder: function () {
            const config = this.fiatConfig;
            return Math.min(config.maxWithdrawAmount, config.maxAvailableAmount);
        }
    },

    mounted() {
        // $.resetValidators();
        this.popupModal = new Modal(this.$refs.confirmModal);
        this.initAsync();
    },

    beforeDestroy() {
        const m = this.popupModal;
        if (m) {
            m.dispose();
        }
    },

    methods: {
        initAsync: async function () {
            const json = await $.callGetApi(this, '/api/v1/bankaccount/list?id=bank');
            if (json.errcode === 0) {
                this.bankAccounts = Object.freeze(json.data);

                // No need to proceed if no bank accounts are saved.
                if (this.bankAccounts.length > 0) {
                    this.getFiatConfigAsync();
                }
            }
        },

        doConfirm: function (e) {
            const paymentSource = this.fiatConfig.paymentSource;
            if (!paymentSource || true !== /^[a-zA-Z0-0_\-]+$/gi.test(paymentSource)) throw new Error('Requires a valid payment source.');

            const frm = $(e.target);
            if (frm.valid()) {
                this.submitting = false;
                // $(this.$refs.confirmModal).modal('show');
                this.popupModal.show();
            } else {
                // Is it because of invalid withdraw amount
                const str = $.trim('  ' + this.amount);
                if (str.length > 0) {
                    const amt = this.amount * 1;
                    if (!isNaN(amt)) {
                        if (amt > this.maxAmountPerOrder) {
                            $.top_error(this.$t('withdraw.err_amount_exceeded'));
                        } else if (amt < this.fiatConfig.minWithdrawAmount) {
                            $.top_error(this.$t('withdraw.err_too_small_amount'));
                        }
                    }
                }
            }
        },

        doSubmit: function (e) {
            const paymentSource = this.fiatConfig.paymentSource;
            if (!paymentSource || true !== /^[a-zA-Z0-0_\-]+$/gi.test(paymentSource)) throw new Error('Requires a valid payment source.');

            const self = this;
            const frm = $(e.target);
            if (frm.valid()) {
                const data = frm.serializeAsJson();

                // Submit to server now.
                this.submitting = true;
                $.callPostApi(self, '/api/v1/' + paymentSource + '/withdraw', data)
                    .then(function (json) {
                        if (json && json.errcode === 0) {
                            $.top_alert(json.errmsg || self.$t('general.operation_succeeded'));
                        } else {
                            if(json && json.errcode === 101){
                                $.top_error(self.$t('general.password_error'));
                            } else {
                                $.top_error(json.errmsg || self.$t('general.http_error'));
                            }
                        }

                        // close the modal
                        // $(self.$refs.confirmModal).modal('hide');
                        self.popupModal.hide();

                        // Fire an event to notify the completion of a withdraw order.
                        self.getFiatConfigAsync();
                    })
                    .catch(function () {
                        $.top_error(self.$t('general.http_error'));
                    })
                    .then(() => {
                        this.submitting = false;
                    });

                return false;
            }
        },

        getFiatConfigAsync: async function () {
            const config = await $.callPostApi(this, '/api/v1/finance/fiatWithdrawQuota');
            if (config && config.errcode === 0 && config.data) {
                // A valid payment source must be specified.
                if (!config.data.paymentSource || true !== /^[a-zA-Z0-0_\-]+$/gi.test(config.data.paymentSource))
                    throw new Error('Requires a valid payment source.');

                this.fiatConfig = Object.freeze(config.data);
                Vue.nextTick(() => {
                    $.resetValidators();
                });
            }
        }
    }
};
</script>