<template>
    <div class="content-bottom bg-content">
        <div class="mod-head tabs bg-title">
            <span @click="active_tab = 'open'" class="mock-a" :class="{ cur: showOpenOrders }">{{ $t('orders.open_orders') }}</span>
            <span @click="active_tab = 'recent'" class="mock-a" :class="{ cur: !showOpenOrders }">{{ $t('orders.recent_orders') }}</span>
            <div class="actions">
                <router-link to="/boption/orders" class="float-right">{{ $t('boption.orders') }}</router-link>
            </div>
        </div>

        <open-order-list ref="openOrderList" v-if="showOpenOrders" :symbol="symbol" :boptionConfig="boptionConfig" @order-completed="onOrderCompleted" />
        <recent-order-list v-else :symbol="symbol" />
    </div>
</template>

<script>
import OpenOrderList from './BOption_OpenOrderList.vue';
import RecentOrderList from './BOption_RecentOrderList.vue';

export default {
    components: { OpenOrderList, RecentOrderList },

    props: ['symbol', 'boptionConfig'],

    data() {
        return { active_tab: 'open' };
    },

    computed: {
        showOpenOrders: function () {
            return this.active_tab === 'open';
        }
    },

    methods: {
        reloadOpenOrders: function () {
            if (this.showOpenOrders) {
                // reload in a delay.
                const self = this;
                setTimeout(() => {
                    self.$refs.openOrderList.getOpenOrders();
                }, 200);
            }
        },

        onOrderCompleted: function () {
            this.$emit('order-completed');
        }
    }
};
</script>
<style scoped>
.float-right{
    color: #2E86FE;
    word-wrap: break-word;
    word-break: break-all;
}
</style>